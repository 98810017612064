<script>

    import {LMarker, LIcon, LPopup} from 'vue2-leaflet';
    import Visual from '../../../components/visual.vue';
    import MiniPlayer from '../../player/MiniPlayer.vue';

    const DEBUG = false;

    export default {
        name: 'CoverageMapProgram',
        components: {
            LMarker,
            LIcon,
            LPopup,
            Visual,
            'mini-player': MiniPlayer,
        },
        props: {
            program: Object,
            selected_program_uuid: String,
        },
        mounted() {

        },
        data() {
            return {
                logo_visible: false
            }
        },
        computed: {
            visible() {
                return !!this.coordinates;
            },
            coordinates() {
                if (!(this.program.latitude && this.program.longitude)) {
                    return null;
                }
                return [this.program.latitude, this.program.longitude]
            },
            selected() {
                return this.program.uuid === this.selected_program_uuid;
            },
            icon() {
                return '/static/img/coverage-map/icons/studio.png';
            },
        },
        methods: {
            popup_opened: function (a, b, c, d, e) {
                this.logo_visible = true;
            },
            controls: function (action) {

                const _e = new CustomEvent('player:controls', {
                    detail: {
                        do: action,
                        ct: this.program.ct,
                        uuid: this.program.uuid
                    }
                });
                document.dispatchEvent(_e);
            }
        }
    }
</script>

<style lang="scss">
    .coverage-map-program {
        display: flex;
        align-items: center;

        &__visual {
            min-width: 60px;
            height: 60px;
            margin-right: 10px;
            position: relative;

            img {
                object-fit: contain;
            }
            .play-icon {
                background: white;
                opacity: 0;
                position: absolute;
                left: 12px;
                top: 12px;
                width: 36px;
                height: 36px;
                cursor: pointer;
                border-radius: 18px;
                border: 1px solid #000;
                .icon {
                    margin: 6px 0 0 7px;
                    width: 24px;
                    height: 24px;
                }
            }

            &:hover {
                .play-icon {
                    opacity: 1;
                }
            }

        }

        &__meta {
        }
    }
</style>

<template>
    <div
        v-if="visible">
        <l-marker
            :lat-lng="coordinates">
            <l-popup @add="popup_opened">
                <div class="coverage-map-program">
                    <div class="coverage-map-program__visual">
                        <visual v-if="logo_visible" :url="program.logo"></visual>

                        <div
                            class="play-icon">
                            <mini-player
                                hover-color="#000"
                                :program-uuid="program.uuid"></mini-player>
                        </div>
                        <!--
                        <div
                            class="play-icon"
                            @click.prevent="controls('play')">
                            <svg class="icon" viewBox="0 0 24 24">
                                <title>play</title>
                                <path d="M6 2l13 10-13 10z"></path>
                            </svg>
                        </div>
                        -->

                    </div>
                    <div class="coverage-map-program__meta">
                        <a
                            :href="program.detail_url">{{ program.name }}</a>
                    </div>
                </div>
            </l-popup>
            <l-icon
                :icon-url="icon"
                :icon-anchor="[30, 60]"
                :popup-anchor="[0, -30]"
                :icon-size="[60, 60]"
                shadow-url="">
            </l-icon>
        </l-marker>
    </div>
</template>
