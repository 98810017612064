import Vue from 'vue';
import {wrapRequest} from "./requests";
import APIClient from "../api/client";

const DEBUG = false;

const ENSEMBLE_ENDPOINT = '/api/v1/infrastructure/ensemble/';
const PROGRAM_ENDPOINT = '/api/v1/infrastructure/program/';

const state = {
  ensembles: [],
  programs: [],
  player: {
    state: 'stopped',
    program: null,
  }
};

const getters = {
  ensembles: state => state.ensembles,
  programs: state => state.programs,
  program_by_uuid: (state) => (uuid) => {
    return state.programs.find(p => p.uuid === uuid);
      // return state.programs[uuid] || null;
  },
  player: state => state.player,
};

const mutations = {
    update_ensembles: (state, payload) => {
      state.ensembles = state.ensembles.concat(payload);
    },
    update_programs: (state, payload) => {
      state.programs = state.programs.concat(payload);
    },
    update_player: (state, payload) => {

      for (const key in payload) {
          if (payload.hasOwnProperty(key)) {
              if(state.player[key] != payload[key]) {
                Vue.set(state.player, key, payload[key]);
              }
          }
      }
      // state.player = payload;
    },
};

const actions = {
    get_ensembles: async (context, url) => {
      url = url || ENSEMBLE_ENDPOINT;
      if (DEBUG) console.debug('actions - get_ensembles', url);
      let _r = wrapRequest(APIClient.get);
      let {data} = await _r(url);
      context.commit('update_ensembles', data.results);

      // paginate until done
      if(data.next !== null) {
        context.dispatch('get_ensembles', data.next);
      }
    },
    get_programs: async (context, url) => {
      url = url || PROGRAM_ENDPOINT;
      if (DEBUG) console.debug('actions - get_programs', url);
      let _r = wrapRequest(APIClient.get);
      let {data} = await _r(url);
      context.commit('update_programs', data.results);

      // paginate until done
      if(data.next !== null) {
        context.dispatch('get_programs', data.next);
      }
    },

    set_player: (context, {payload}) => {
        console.debug('update player', payload);
        context.commit('update_player', payload);
    },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
