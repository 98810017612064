// global stylesheet import
import '../sass/screen.sass';

import settings from './settings';

// module imports
import Turbolinks from 'turbolinks';

// initializer
import AppInitializer from './initializer';
import Analytics from './utils/analytics';

const DEBUG = false;
const USE_TURBOLINKS = ! settings.toolbarEnabled;

const anayltics = new Analytics();
const content_changed = new Event('content:changed');

document.addEventListener("DOMContentLoaded", (e) => {
  document.dispatchEvent(content_changed);
});

document.addEventListener('turbolinks:load', (e) => {

  if (Object.keys(e.data.timing).length > 0) {
    if (DEBUG) console.debug('turbolinks loaded sequential request');
    document.dispatchEvent(content_changed);

  } else {
    if (DEBUG) console.debug('turbolinks loaded initial request');
    document.dispatchEvent(content_changed);
  }

});


$(() => {

  // initializer has to wait for dom ready, as
  // vue apps need container to mount
  const initializer = new AppInitializer({});

  if (USE_TURBOLINKS) {
    Turbolinks.start();
  } else {
    console.warn('turbolinks disabled');
    // for compatibility we have to provide `Turbolinks.visit`
    Turbolinks.visit = (url) => {
      console.debug('patched visit', url);
      document.location.href = url;
    }
  }

  document.addEventListener("navigate:to", (e) => {
      Turbolinks.visit(e.detail.url);
  });

});

document.addEventListener('cms-content-refresh', (e) => {
  //
}, false);
