<script>

    const DEBUG = false;
    import Visual from '../../../components/visual.vue';
    import PlayingIcon from './PlayingIcon.vue';
    import LoadingIcon from './LoadingIcon.vue';

    export default {
        name: 'PlayerProgram',
        components: {
            Visual,
            PlayingIcon,
            LoadingIcon,
        },
        props: {
            program: Object,
            current_program: Object,
            player_state: String,
            display: {
                type: String,
                default: 'default'
            },
        },
        data() {
            return {
                has_changed: false,
                is_hover: false,
            }
        },
        mounted() {

        },
        computed: {
            is_current() {
                if (!this.current_program) {
                    return false;
                }
                return (this.program.uuid === this.current_program.uuid)
            },
            // 'now_playing' contains last airplay for *any* program
            now_playing() {
                return this.$store.getters['bcmon/now_playing'];
            },
            program_now_playing() {
                const index = this.now_playing.map(({program_uuid}) => program_uuid).indexOf(this.program.uuid);
                if (index > -1) {
                    return this.now_playing[index];
                }
            },

            is_loading() {
                return (this.is_current && this.player_state === 'loading');
            },

            is_playing() {
                return (this.is_current && this.player_state === 'playing');
            },

            is_small() {
                return (this.display === 'small');
            },

        },
        methods: {
            click: function(action) {
                if(this.is_small) {
                    // small version, displayed in bottom player
                    const _e = new CustomEvent('navigate:to', {
                      detail: {
                        url: this.program.detail_url
                      }
                    });
                    document.dispatchEvent(_e);
                } else {
                    // larger version, displayed listing
                    this.controls(action);
                }
            },
            controls: function (action) {
                if (this.is_current && this.player_state === 'playing') {
                    return;
                }
                const _action = {
                    'do': action,
                    'uuid': this.program.uuid
                };
                this.$emit('controls', _action);
            },
            mouseover: function () {
                this.is_hover = true;
            },
            mouseleave: function () {
                this.is_hover = false;
            },
        },
        watch: {
            'program_now_playing': function (val) {
                this.has_changed = true;
                setTimeout(() => {
                    this.has_changed = false;
                }, 5000)
            },
        }
    }
</script>

<style lang="scss" scoped>

    .program {
        display: flex;
        bottom: 0;
        width: 100%;
        height: 48px;
        padding: 0 0 0 12px;
        align-items: center;
        cursor: pointer;
        color: #000;
        &__visual {
            width: 32px;
            min-width: 32px;
            height: 32px;
        }

        &__meta {
            padding: 0 10px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            line-height: 1rem;

            .current-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                max-width: 300px;
                font-size: 80%;
            }
        }

        &__controls {
            display: inline-flex;
            width: 48px;
            height: 48px;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            background: #000;
            color: #50e3c2;
        }

        &--is-current {
            color: #fff;
            background: black;
        }

        &--is-small {
            color: #fff;

            &:hover {
                color: inherit;
                background: black;
            }
        }

        &--is-loading {
            cursor: wait;
        }
    }
</style>

<template>
    <div
        class="program"
        :class="{ 'program--is-current': is_current, 'program--is-loading': is_loading, 'program--is-playing': is_playing, 'program--is-small': is_small }"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
        @click.prevent="click('play')"
        v-if="program">
        <div class="program__visual">
            <visual v-if="program.logo" :url="program.logo"></visual>
        </div>
        <div class="program__meta">
            {{ program.name }}<br>
            <span class="current-title" v-if="program_now_playing">
                {{ program_now_playing.text }}
            </span>
            <span class="current-title" v-else-if="program.default_title">
                {{ program.default_title }}
            </span>
        </div>
        <div
            v-if="(is_current && !is_small)"
            class="program__controls">
            <div
                v-if="(player_state === 'loading')">
                <loading-icon
                    :color="is_hover ? '#50e3c2' : '#fff'"
                ></loading-icon>
            </div>
            <div
                v-if="(player_state === 'error')">
                <span>E</span>
            </div>
            <div
                v-if="(player_state === 'playing')">
                <playing-icon
                    :color="is_hover ? '#50e3c2' : '#fff'"
                ></playing-icon>
            </div>
        </div>
    </div>
</template>
