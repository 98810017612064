import APIClient from "../api/client";

const DEBUG = false;
const SUBSCRIBE_API_ENDPOINT = '/api/v1/newsletter/subscribe/';

export default class NewsletterApp {

  setup() {
    if (DEBUG) console.debug('NewsletterApp - init');

    $(document).on('submit', 'form.newsletter-subscribe', (e) => {
      e.preventDefault();
      const container = $(e.currentTarget);
      let data = [];
      // map to key/value
      $.map(container.serializeArray(), (n, i) => {
        data[n['name']] = n['value'];
      });
      if (DEBUG) console.debug('NewsletterApp - submit', data);

      this.subscribe(data, container);
    })

  };

  subscribe(payload, container) {
    if (DEBUG) console.debug('NewsletterApp - subscribe', payload);

    // W.T.F.???
    payload = {
      email: payload.email
    };

    container.addClass('is-loading');
    const feedback_container = $('.feedback-container', container);

    APIClient.post(SUBSCRIBE_API_ENDPOINT, payload)
      .then(function (response) {
        container.removeClass('has-error');
        container.removeClass('is-loading');
        container.addClass('is-subscribed');

        feedback_container.html(response.data.message);

      })
      .catch(function (error, a, b, c) {
        container.addClass('has-error');
        container.removeClass('is-loading');
        console.log(error, error.response);
        if( error.response ) {
          let error_text = '';
          error.response.data.errors.forEach((el) => {
            error_text += el.message + '<br>';
          });
          feedback_container.html(error_text);
        }

      });

  }
}

//module.exports = NewsletterApp;
