import Turbolinks from "turbolinks";

export default class SiteApp {

  setup() {

    // prevents page bg flicker in IE11 -> smooth scrolling feature
    // TODO: proper place?
    if (navigator.userAgent.match(/Trident\/7\./)) { // if IE
      $('body').on("mousewheel", (e) => {
        e.preventDefault();
        const offset = window.pageYOffset - e.wheelDelta;
        window.scrollTo(0, offset);
      });
    }

    $(document).on('click', '[data-href]', (e) => {
      const href = $(e.currentTarget).data('href');
      Turbolinks.visit(href);
    });

  };

}
