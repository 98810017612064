import settings from '../settings';

const DEBUG = false;
const GANALYTICS_TRACKING_CODE = settings.GANALYTICS_TRACKING_CODE;

export default class Analytics {

  constructor() {
    if (DEBUG) console.debug('Analytics - init', GANALYTICS_TRACKING_CODE);

    if (!GANALYTICS_TRACKING_CODE) {
      console.warn('GANALYTICS_TRACKING_CODE not configured');
      return;
    }

    document.addEventListener("content:changed", (e) => {
      const url = document.location.href.replace(/https?:\/\/[^\/]+/i, "");
      this.sendPageview(url);
    });
  };

  sendPageview(url) {
      if (DEBUG) console.debug('Analytics - sendPageview', url);
      ga('set', 'page', url);
      ga('send', 'pageview');
  };

}
