import {Luminous, LuminousGallery} from 'luminous-lightbox';


const DEBUG = true;

export default class Lightbox {

  setup() {
    if (DEBUG) console.debug('LightboxApp - init');

    this.query_selector = '.image-container';

    this.box = null;

    this.lightbox_options = {
      sourceAttribute: "data-src",
      caption: null,
      openTrigger: "click",
      closeTrigger: "click",
      closeWithEscape: true,
      closeOnScroll: true,
      showCloseButton: true,
      // appendToNode: document.body,
      // appendToSelector: document.querySelector('#base'),
      appendToSelector: '#base',
      onOpen: null,
      onClose: null,
      injectBaseStyles: false
    };
    this.lightbox_gallery_options = {
      arrowNavigation: true
    };

    this.build_lightbox();
    document.addEventListener("content:changed", (e) => {
      if (DEBUG) console.debug('rebuild lightbox');
      this.reset_lightbox();
      this.build_lightbox();
    });

  }

  reset_lightbox() {
    if (DEBUG) console.debug('LightboxApp - reset');
    if(this.box) {
      try {
        this.box.luminousInstances.forEach(instance => instance.destroy());
        this.box.destroy();
      } catch(e) {
        if (DEBUG) console.log(e);
      }
    }
  }

  build_lightbox() {

    const elements = document.querySelectorAll(this.query_selector);
    if (DEBUG) console.debug('LightboxApp - build', elements.length);

    if(elements.length) {
      setImmediate(() => {
        this.box = new LuminousGallery(
          document.querySelectorAll(this.query_selector),
          this.lightbox_gallery_options,
          this.lightbox_options
        );
      })
    }
  }
}
