<script>

    import {Howl, Howler} from 'howler';
    import settings from '../../settings';
    import PlayerProgram from './components/PlayerProgram.vue';
    import LoadingIcon from './components/LoadingIcon.vue';
    import PlayButton from './components/PlayButton.vue';

    const DEBUG = false;

    export default {
        name: 'Player',
        components: {
            PlayerProgram,
            LoadingIcon,
            'play-button': PlayButton,
        },
        props: [],
        mounted() {
            this.initialize_player();
            $(document).on('click', '[data-player-action]', (e) => {
                const el = $(e.currentTarget);
                const action = el.data('player-action');
                const ct = el.data('player-ct');
                const uuid = el.data('player-uuid');
                const _e = new CustomEvent('player:controls', {
                    detail: {
                        do: action,
                        ct: ct,
                        uuid: uuid
                    }
                });
                document.dispatchEvent(_e);
            });
            document.addEventListener('player:controls', (e) => {
                const action = e.detail;
                // console.debug('player:controls', action);
                this.controls(action);
            }, false);

            if (this.programs.length < 1) {
                this.$store.dispatch('infrastructure/get_programs');
            }

            // esc key
            $(document).on('keydown', (e) => {
                if(this.program_list_visible && e.which === 27) {
                    this.hide_program_list();
                }
            });

            this.program_filter_country = settings.COUNTRY_CODE;

        },
        data() {
            return {
                stream_url: settings.STREAM_URL,
                volume: 100,
                sound: null,
                current_program: null,
                visible: false,
                state: 'stopped',
                program_list_visible: false,
                program_search: '',
                program_filter_country: false,
            }
        },
        computed: {
            player() {
                return this.$store.getters['infrastructure/player'];
            },
            programs() {
                const programs = this.$store.getters['infrastructure/programs'];
                if(this.program_filter_country) {
                    return programs.filter((obj) => obj.country_code === this.program_filter_country)
                }
                return programs;
            },
            filtered_programs() {
                const q = this.program_search.toLowerCase();
                if (q.length < 2) {
                    return this.programs;
                }
                return this.programs.filter((obj) => obj.name.toLowerCase().includes(q));
            },
            // now_playing() {
            //     return this.$store.getters['bcmon/now_playing'];
            // },
        },
        methods: {
            initialize_player: function () {
                if (DEBUG) console.debug('Player: - initialize_player');
            },
            controls: function (action) {
                if (DEBUG) console.debug('controls', action);
                if (action.do === 'play') {
                    const current_index = this.programs.map(({uuid}) => uuid).indexOf(action.uuid);
                    const program = this.programs[current_index];
                    // const src = `${this.stream_url}${program.uuid}`;
                    const src = program.public_stream_url;
                    this.current_program = program;

                    this.visible = true;
                    this.state = 'loading';

                    this.set_player({program: program, state: 'loading'});

                    if (this.sound) {
                        this.sound.stop();
                        this.sound.unload();
                    }
                    this.sound = new Howl({
                        src: [src],
                        html5: true,
                        onplay: (e) => {
                            console.debug('onplay', e);
                            this.state = 'playing';

                            this.set_player({state: 'playing'});

                        },
                        onloaderror: (id) => {
                            if (id) {
                                console.warn('unable to load', id);
                                this.state = 'error';

                                this.set_player({state: 'error'});

                            }
                        },
                    });
                    this.sound.play();
                }
                if (action.do === 'stop') {
                    if (this.sound) {
                        this.sound.stop();
                        this.sound.unload();
                    }
                    this.current_program = null;
                    // this.visible = false;
                    this.state = 'stopped';

                    this.set_player({program: null, state: 'stopped'});

                }
            },

            set_player: function(payload) {
              this.$store.dispatch('infrastructure/set_player', { payload: payload });
            },


            toggle_program_list: function () {
                if(! this.program_list_visible) {
                    this.show_program_list();
                } else {
                    this.hide_program_list();
                }
            },
            show_program_list: function() {
                this.program_search = '';
                this.program_list_visible = true;
                // this.$nextTick(() => {
                //     // this.$refs.search.focus();
                // })
            },
            hide_program_list: function() {
                this.program_list_visible = false;
            },
            play_random_program: function () {
                const program = this.programs[Math.floor(Math.random() * this.programs.length)];
                this.controls({
                    do: 'play',
                    uuid: program.uuid
                })
            },
        }
    }
</script>

<style lang="scss">

</style>


<style lang="scss" scoped>

    .player {
        background: #000;
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 0 32px;
        height: 48px;
        z-index: 998;
        color: #fff;
        display: flex;
        font-size: 90%;

        .icon {
            fill: #fff;
            opacity: 1;
            cursor: pointer;
            width: 1.2rem;
            height: 1.2rem;

            &:hover {
                opacity: .75;
            }
        }

        &__controls {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__control {
            padding-right: .8rem;

            &:last-child {
                padding-right: 0;
            }

            &--play,
            &--pause {
                .icon {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }

            &--loading {
                width: 2.6rem;
                display: flex;
                justify-content: center;
            }

            &--toggle-program-list {
                margin-left: 12px;
            }
        }

        &__current {
            padding-left: 12px;
        }

        &__settings {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .volume {

            }

        }

    }

    @media only screen and (max-width: 600px) {
        .player {
            padding: 0 16px;

            &__controls {
                width: 100%;
            }

            &__control {
                &--toggle-program-list {
                    flex-grow: 1;
                    text-align: right;
                }
            }

            &__current {
                display: none;
            }

            &__settings {
                display: none;
            }
        }
    }


    .program-list {
        position: fixed;
        bottom: 60px;
        left: 16px;
        z-index: 999;
        max-height: 50vh;
        max-width: 600px;
        min-width: 600px;
        overflow-y: auto;
        overflow-x: hidden;

        background: #50e3c2;
        color: #fff;

        box-shadow: 2px 2px 4px 1px rgba(#000, .2);

        // position: relative;

        &__header {
            position: absolute;
            top: 0;
            height: 32px;
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: flex-end;
            padding-right: 7px;

            a {
                color: #000;
                cursor: pointer;
            }

            .country-selector {
                flex-grow: 1;
            }

        }

        &__programs {
            // position: absolute;
            width: 100%;
            max-height: calc(50vh - 42px);
            overflow-y: auto;
            margin-top: 32px;
            padding-bottom: 50px;

            // TODO: create scrollbar mixin
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
            }
        }

        &__search {
            background: #50e3c2;
            position: absolute;
            width: 100%;
            // height: 132px;
            bottom: 0;
            padding: 10px;

            input {
                width: 100%;
                padding: 10px 10px 8px;
                background: #000;
                color: inherit;
                outline: none;
                border: 1px solid rgba(#fff, .3);
            }
        }

    }


    @media only screen and (max-width: 600px) {
        .program-list {
            left: 0;
            bottom: 48px;
            // height: calc(100vh - 49px);
            max-height: calc(100vh - 48px);
            width: 100%;
            max-width: 100%;
            min-width: 0;

            &__programs {
                max-height: calc(100vh - 49px);

                /*width: 400px;*/
                /*max-width: 400px;*/
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

</style>

<template>
    <div>
        <div
            v-if="visible"
            class="player">
            <div class="player__controls">


                <!--
                <play-button
                    color="#fff"
                    :state="state"
                    :scale="1.4"
                    @play="play_random_program"
                    @stop="controls({do: 'stop'})"></play-button>-->

                <div
                    v-if="(state === 'stopped')"
                    @click.prevent="play_random_program"
                    class="player__control player__control--play">
                    <svg class="icon" id="icon-skip-forward" viewBox="0 0 24 24">
                        <title>play</title>
                        <path d="M6 2l13 10-13 10z"></path>
                    </svg>
                </div>
                <div
                    v-if="(state === 'playing')"
                    @click.prevent="controls({do: 'stop'})"
                    class="player__control player__control--pause">
                    <svg class="icon" id="icon-skip-forward" viewBox="0 0 24 24">
                        <title>pause</title>
                        <path d="M6 3h4v18h-4v-18z"></path>
                        <path d="M14 3h4v18h-4v-18z"></path>
                    </svg>
                </div>
                <div
                    v-if="(state === 'loading')"
                    class="player__control player__control--loading">
                    <loading-icon
                        color="#fff"
                    ></loading-icon>
                </div>
                <div
                    @click.prevent="toggle_program_list"
                    class="player__control player__control--toggle-program-list">
                    <svg class="icon" id="icon-skip-forward" viewBox="0 0 24 24">
                        <title>toggle-program-list</title>
                        <rect x="0" y="1" width="24" height="4" stroke="none" fill="white"/>
                        <rect x="0" y="10" width="24" height="4" stroke="none" fill="white"/>
                        <rect x="0" y="19" width="24" height="4" stroke="none" fill="white"/>
                    </svg>
                </div>
            </div>
            <div class="player__current">
                <player-program
                    v-if="current_program"
                    @controls="controls"
                    display="small"
                    :key="current_program.uuid"
                    :player_state="state"
                    :program="current_program"
                    :current_program="current_program"></player-program>
            </div>
            <div class="player__settings">
                <!--
                <div class="volume">
                    <input type="range" min="0" max="100" step="1" v-model="volume">
                </div>
                -->
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="program_list_visible"
                class="program-list">
                <div class="program-list__header">
                    <div class="country-selector">
                        <!--
                        <a
                            @click.prevent="program_filter_country = 'ch'"
                        >CH</a>

                        <a
                            @click.prevent="program_filter_country = 'fr'"
                        >FR</a>
                        -->
                    </div>
                    <a
                     @click.prevent="hide_program_list">close</a>
                </div>
                <div class="program-list__programs">
                    <player-program
                        v-for="program in filtered_programs"
                        v-if="program.has_relay_stream"
                        @controls="controls"
                        :key="program.uuid"
                        :player_state="state"
                        :program="program"
                        :current_program="current_program"></player-program>
                </div>
                <div class="program-list__search">
                    <input
                        ref="search"
                        v-model="program_search" placeholder="Search">
                </div>
            </div>
        </transition>
    </div>
</template>
