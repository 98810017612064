import Vue from 'vue';
import Vuex from 'vuex';
import { wrapRequest } from './requests';
import APIClient from "../api/client";
import infrastructure from './_infrastructure';
import bcmon from './_bcmon';

const DEBUG = false;

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    infrastructure,
    bcmon,
  },
  // plugins: []
});

export default store;
