<script>

    import {LMarker, LIcon, LPopup } from 'vue2-leaflet';
    const DEBUG = false;

    export default {
        name: 'CoverageMapSite',
        components: {
            LMarker,
            LIcon,
            LPopup
        },
        props: {
            site: Object,
            ensemble: Object
        },
        mounted() {

        },
        computed: {
            visible() {
                return !!this.coordinates;
            },
            coordinates() {
              if(! (this.site.latitude && this.site.longitude)) {
                  return null;
              }
              return [this.site.latitude, this.site.longitude]
            },
            icon() {
                const base_url ='/static/img/coverage-map/icons/';
                return `${base_url}site.${this.site.type}.${this.site.is_active ? 'active' : 'inactive'}.png`;
            }
        },
        methods: {
          navigate_to: function (obj) {
            this.$emit('navigate_to', obj);
          }
        }
    }
</script>

<style lang="scss" scoped>
    .coverage-map-site {
        // font-size: 120%;
    }
</style>

<template>
    <div
        v-if="visible"
        class="coverage-map-site">
      <l-marker :lat-lng="coordinates">
        <l-popup>
          <span class="title_">Sendeanlage:</span>
          <br>
          <a @click.prevent="navigate_to(site)"
             href="#">{{ site.name }}</a>
          <br>
          <span class="title_">Sendegebiet:</span>
          <br>
          <a
              v-if="ensemble"
              @click.prevent="navigate_to(ensemble)"
              :href="ensemble.detail_url">{{ ensemble.island_name }}</a>
        </l-popup>
        <l-icon
          :icon-url="icon"
          :icon-anchor="[30, 57]"
          :popup-anchor="[0, -60]"
          :icon-size="[60, 60]"
          shadow-url="">
        </l-icon>
      </l-marker>
    </div>
</template>
