const DEBUG = false;
import {detect} from 'detect-browser';

export default class PageBG {

  constructor() {
    this.bg_loaded = false;
    this.browser = detect();
  };

  setup() {

    if (DEBUG) console.debug('PageBG - setup');

    if (this.bg_loaded) {
      if (DEBUG) console.debug('PageBG - bg already loaded -> skip');
      return;
    }

    const container = document.getElementById('page_bg');
    const dataset = container.dataset;

    this.image_container = document.getElementById('page_bg_image');
    this.video_container = document.getElementById('page_bg_video');

    if (DEBUG) console.debug('dataset:', dataset);

    this.show_video = (this.browser && this.browser.name === 'chrome');

    if (dataset.imageLarge) {
      this.load_image(dataset)
    }

    this.bg_loaded = true;

  };

  load_image(dataset) {

    if (DEBUG) console.debug('PageBG - load_image');

    const img = new Image();
    const time_start = new Date().getTime();

    const image_loaded = () => {
      const time_to_load = new Date().getTime() - time_start;
      if (DEBUG) console.debug('PageBG - image loaded in', time_to_load);
      // listener needs to be removed. if not firefox hangs in a 'load loop'
      img.removeEventListener('load', image_loaded, true);
      this.image_container.appendChild(img);
      this.image_container.classList.remove('hidden');

      if(this.show_video && time_to_load < 5000 && dataset.videoMp4) {
        if (dataset.videoMp4) {
          setTimeout(() => {
            this.load_video(dataset)
          }, 5000)
        }
      }

    };
    img.src = dataset.imageLarge;
    img.addEventListener('load', image_loaded, true);

  };

  load_video(dataset) {

    let source = document.createElement('source');
    source.type = 'video/mp4';
    source.src = dataset.videoMp4;

    this.video_container.appendChild(source);
    this.video_container.pause();
    this.video_container.classList.remove('hidden');

    setTimeout(() => {
      this.video_container.play();
    }, 500)

  };

}

// module.exports = PageBG;
