const DEBUG = false;

export default class Topbar {

  setup() {
    this.build_navigation();

    document.addEventListener("content:changed", (e) => {
      if (DEBUG) console.log('Topbar: - rebuild_navigation');
      this.reset_navigation();
      this.build_navigation();
    });

  };

  build_navigation() {

    if (DEBUG) console.log('Topbar: - build_navigation');

    let nav = $('#nav');
    let tb = $('#topbar');
    let html = $('html');
    let nav_active = false;

    $(document).off('click', 'a[data-toggle-menu]').on('click', 'a[data-toggle-menu]', (e) => {
      e.preventDefault();

      const scroll_bar_gap = window.innerWidth - document.documentElement.clientWidth;
      let is_ie_edge = false;

      if (/Edge\/\d./i.test(navigator.userAgent)) {
        is_ie_edge = true;
      }

      if (nav_active) {
        tb.css('padding-right', "");
        if (is_ie_edge) {
          $("#page_bg >*").css('padding-right', "");
        }
        nav.removeClass('is-expanded');
        html.removeClass('nav-is-expanded');
        nav_active = false;
        this.reset_navigation();
      }
      else {
        nav.addClass('is-expanded');
        html.addClass('nav-is-expanded');
        nav_active = true;
      }

      html.addClass('in-transition');
      let transition_event = get_transition_event();

      nav.one(transition_event, (e) => {
        html.removeClass('in-transition');
        if (nav_active) {
          tb.css('padding-right', scroll_bar_gap);
          if (is_ie_edge) {
            $("#page_bg >*").css('padding-right', scroll_bar_gap);
          }
        }
      });
    });

    // menu slide animations
    let accordion_item = $('.menu-main .menu__item--has-children .menu__label');

    accordion_item.on('click',  (e) => {
      //e.preventDefault();
      let menu_item = $(e.currentTarget).parent();
      let subnav = menu_item.children('.menu__submenu');
      let subnav_height = 0;

      menu_item.toggleClass('menu__item--is-active');
      menu_item.siblings('.menu__item--is-active').find('.menu__item--is-active').removeClass('menu__item--is-active');
      menu_item.siblings('.menu__item--is-active').removeClass('menu__item--is-active');

    });
  }

  reset_navigation() {
    let nav = $('#nav');
    let html = $('html');

    nav.find('.menu__item--is-active').removeClass('menu__item--is-active');
    nav.removeClass('is-expanded');
    html.removeClass('nav-is-expanded');
  }


};

const get_transition_event = () => {
  var t, el = document.createElement("fakeelement");

  var transitions = {
    "transition": "transitionend",
    "OTransition": "oTransitionEnd",
    "MozTransition": "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
};


//module.exports = Topbar;
