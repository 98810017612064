<script>

    import settings from '../../settings';
    import PlayButton from './components/PlayButton.vue';

    const DEBUG = true;

    export default {
        name: 'MiniPlayer',
        components: {
            'play-button': PlayButton,
        },
        props: {
            programUuid: {
              type: String,
              required: true
            },
            size: {
              // type is actually number, but need `String` to be able to pass via `data-` attributes.
              type: [Number, String],
              default: 24
            },
            color: {
                type: String,
                default: '#fff'
            },
            hoverColor: {
                type: String,
                default: null
            },
        },
        mounted() {

        },
        data() {
            return {
                isHover: false,
                programCt: 'infrastructure.program',
                // onAir: 'foo'
            }
        },
        computed: {

            parsedSize() {
                return parseInt(this.size);
            },

            style() {
                return {
                    width: `${this.size}px`,
                    height: `${this.size}px`,
                }
            },
            program() {
                return this.$store.getters['infrastructure/program_by_uuid'](this.programUuid);
            },
            player() {
                return this.$store.getters['infrastructure/player'];
            },
            state() {
                if(!(this.program && this.player)) {
                    return null;
                }
                if(this.player.program && this.player.program.uuid === this.program.uuid) {
                    return this.player.state;
                }
                return 'stopped'
            },
            onAir() {
                return this.$store.getters['bcmon/now_playing_by_program_uuid'](this.programUuid);
            },
        },
        methods: {
            controls: function (action) {
                if (DEBUG) console.debug('MiniPlayer: - controls', action);
                const _e = new CustomEvent('player:controls', {
                    detail: {
                        do: action,
                        ct: this.programCt,
                        uuid: this.programUuid
                    }
                });
                document.dispatchEvent(_e);
            },
            play: function () {
                if (DEBUG) console.debug('MiniPlayer: - play');
                // this.state = 'playing';
                this.controls('play')
            },
            stop: function () {
                if (DEBUG) console.debug('MiniPlayer: - stop');
                // this.state = 'stopped';
                this.controls('stop')
            },
        }
    }
</script>


<style lang="scss" scoped>

    @import "../../../sass/settings";

    .mini-player {
        position: relative;
        font-size: $g-body-font-size;

        .on-air {
            background: #fff;
            border: 1px solid #fff;
            border-radius: 2px;
            color: #000;
            height: 28px;
            min-width: 120px;
            right: 40px;
            top: -2px;
            position: absolute;
            font-size: 90%;
            display: flex;
            align-items: center;
            padding: 2px 10px 0;
            white-space: nowrap;
        }

    }
</style>

<template>
    <div
        class="mini-player"
        :style="style"
        @mouseover="isHover=true"
        @mouseleave="isHover=false">
        <play-button
            :color="color"
            :hover-color="hoverColor"
            :state="state"
            :scale="1"
            :size="parsedSize"
            @play="play"
            @stop="stop"></play-button>

        <!--
        <div v-if="(isHover && onAir)" class="on-air">
            <span>{{ onAir.text }}</span>
        </div>
        -->

    </div>
</template>
