<script>

    const DEBUG = false;

    import {LMarker, LIcon, LPopup, LImageOverlay} from 'vue2-leaflet';
    import CoverageMapSite from './coverage-map-site.vue';

    export default {
        name: 'CoverageMapEnsemble',
        components: {
            LMarker,
            LIcon,
            LPopup,
            LImageOverlay,
            CoverageMapSite
        },
        props: {
            ensemble: Object,
            expanded: Boolean,
            show_overlay: Boolean,
        },
        mounted() {

        },
        computed: {
            visible() {
                return !!this.coordinates;
            },
            coordinates() {
                if (!(this.ensemble.latitude && this.ensemble.longitude)) {
                    return null;
                }
                return [this.ensemble.latitude, this.ensemble.longitude]
            },
            icon() {
                if (this.expanded) {
                    return '/static/img/coverage-map/icons/studio.png';
                }
                return '/static/img/coverage-map/icons/ensemble.png';
            },
            overlay() {
                if (!this.show_overlay) {
                    return null;
                }
                const coverage = this.ensemble.coverage;

                if (!coverage || !coverage.overlay || !coverage.bounds) {
                    return null;
                }
                return {
                    url: coverage.overlay,
                    bounds: [
                        [coverage.bounds.south, coverage.bounds.west],
                        [coverage.bounds.north, coverage.bounds.east]
                    ]
                }
            }
        },
        methods: {
          navigate_to: function (obj) {
            this.$emit('navigate_to', obj);
          }
        }
    }
</script>

<style lang="scss" scoped>
    // unfortunately scoped styles don't work with vue.js/leaflet
</style>

<template>
    <div
        v-if="visible"
        class="coverage-map-ensemble">
        <div v-if="expanded">
            <!-- in expanded mode we show overlay and sites -->
            <l-image-overlay
                v-if="overlay"
                :opacity="0.7"
                :url="overlay.url"
                :bounds="overlay.bounds"></l-image-overlay>

            <coverage-map-site
                v-for="site in ensemble.sites"
                @navigate_to="navigate_to"
                :site="site"
                :ensemble="ensemble"
                :key="site.uuid"></coverage-map-site>
        </div>
        <div v-else>
            <!-- in compact mode we only show a marker for the 'island' -->
            <l-marker
                :lat-lng="coordinates">
                <l-popup>
                    <span>Sendegebiet:</span>
                    <br>
                    <a
                        @click.prevent="navigate_to(ensemble)"
                        href="#">{{ ensemble.island_name }}</a>
                </l-popup>
                <l-icon
                    :popup-anchor="[0, 0]"
                    :icon-url="icon"
                    :icon-anchor="[30, 30]"
                    :icon-size="[60, 60]"
                    shadow-url="">
                </l-icon>
            </l-marker>
        </div>
    </div>
</template>
