

const DEBUG = false;

import laziestloader from 'laziestloader';

export default class Lazyloader {

  setup() {
    if (DEBUG) console.debug('Lazyload - init');
    this.process_images();
    document.addEventListener("content:changed", (e) => {
      this.process_images();
    });
  }
  process_images() {
    if (DEBUG) console.debug('LightboxApp - process_images');
    setImmediate(() => {
      $('.lazy-image').laziestloader();
    })
  }
}
