<script>
    export default {
        name: 'PlayingIcon',
        props: {
            color: {
                type: String,
                default: '#fff'
            },
        },
        computed: {
            style() {
                return {
                    background: this.color
                }
            },
        },
    }
</script>
<style lang="scss" scoped>

    .bars {
        width: 14px;
        height: 14px;
        text-align: center;
        font-size: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .bars > div {
        height: 100%;
        width: 2px;
        display: inline-block;

        animation: stretch 1.3s infinite;
    }

    .bars .rect2 {
        animation-delay: -.7s;
    }

    .bars .rect1 {
        animation-delay: .1s;
    }

    .bars .rect5 {
        animation-delay: -.2s;
    }

    .bars .rect2 {
        animation-delay: -.4s;
    }

    .bars .rect3 {
        animation-delay: -0.9s;
    }

    .bars .rect4 {
        animation-delay: -0.6s;
    }

    @keyframes stretch {
        0%, 80%, 100% {
            height: 20%;
        }
        20% {
            height: 90%;
        }
        40% {
            height: 70%;
        }
        60% {
            height: 80%;
        }
    }

</style>
<template>
    <div class="bars">
        <div class="rect1" :style="style"></div>
        <div class="rect2" :style="style"></div>
        <div class="rect3" :style="style"></div>
        <div class="rect4" :style="style"></div>
        <div class="rect5" :style="style"></div>
    </div>
</template>
