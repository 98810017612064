import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './store';
import settings from './settings';

// 'apps'
import Player from './apps/player/Player.vue';
import MiniPlayer from './apps/player/MiniPlayer.vue';
import CoverageMap from './apps/infrastructure/coverage-map.vue';
// components
import SiteApp from './apps/site';
import Topbar from './components/topbar';
import PageBG from './components/page-bg';
import EmbedCode from './components/embed-code';
import NewsletterApp from './components/newsletter';
import Lightbox from './components/lightbox';
import Lazyloader from './components/lazyloader';

// const DEBUG = document.settings.DEBUG;
const DEBUG = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: settings.LANGUAGE_CODE || 'en'
});

class AppInitializer {

  constructor(opts) {
    if (DEBUG) console.debug('AppInitializer - constructor');
    this.apps = [];
    this.components = {};
    this.setup_permanent_apps();
    this.setup_components();

    this.setup_dynamic_apps();

    document.addEventListener("content:changed", (e) => {
      this.setup_dynamic_apps();
    });

    document.addEventListener('cms-content-refresh', (e) => {

      // alert('cms-content-refresh')

      for (const key of Object.keys(this.components)) {
        console.debug('setup', key)
        this.components[key].setup();
      }
    }, false);


  };

  setup_components() {

    this.components['SiteApp'] = new SiteApp();
    this.components['Topbar'] = new Topbar();
    this.components['PageBG'] = new PageBG();
    this.components['EmbedCode'] = new EmbedCode();
    this.components['NewsletterApp'] = new NewsletterApp();
    this.components['Lightbox'] = new Lightbox();
    this.components['Lazyloader'] = new Lazyloader();

    for (const key of Object.keys(this.components)) {
      this.components[key].setup();
    }

  };

  setup_permanent_apps() {

    /**************************************************************
     * Player App
     **************************************************************/
    const player_app_container = document.getElementById('player_app');
    if (player_app_container) {
      const PlayerComponent = Vue.extend(Player);

      this.apps['Player'] = new PlayerComponent({
        store,
        el: player_app_container
      });
    }
  };

  setup_dynamic_apps() {

    /**************************************************************
     * CoverageMap
     **************************************************************/
    const coverage_map_container = document.getElementById('coverage_map');
    if (coverage_map_container) {
      if (DEBUG) console.debug('initialize coverage map');
      const CoverageMapComponent = Vue.extend(CoverageMap);
      this.apps['CoverageMap'] = new CoverageMapComponent({
        el: coverage_map_container,
        store,
        i18n,
        propsData: coverage_map_container.dataset
      });
    }


    /**************************************************************
     * MiniPlayer
     **************************************************************/
    const MiniPlayerComponent = Vue.extend(MiniPlayer);
    document.querySelectorAll('[data-miniplayer]').forEach(el => {
      new MiniPlayerComponent({
        el: el,
        store,
        i18n,
        propsData: el.dataset
      });
    })

  };
}

export default AppInitializer;

// module.exports = AppInitializer;


// document.addEventListener("content:changed", (e) => {
//   console.debug('content:changed', e);
// });
