<script>

    import LoadingIcon from './LoadingIcon.vue';
    // import PlayingIcon from './PlayingIcon.vue';
    import PlayingIcon from './PlayingIconSVG.vue';

    export default {
        name: 'PlayButton',
        components: {
            'loading-icon': LoadingIcon,
            'playing-icon': PlayingIcon,
        },
        props: {
            state: {
                type: String,
                default: 'stopped'
            },
            scale: {
                type: Number,
                default: 1
            },
            size: {
                type: Number,
                default: 24
            },
            color: {
                type: String,
                default: '#fff'
            },
            hoverColor: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                isHover: false,
            }
        },
        computed: {
            iconColor() {
              return (this.isHover && this.hoverColor) ? this.hoverColor : this.color;
            },
            style() {
                return {
                    transform: `scale(${this.scale})`,
                    cursor: (this.isLoading) ? 'wait' : 'pointer',
                    fill: this.iconColor
                }
            },
            isPlaying() {
                return this.state === 'playing';
            },
            isLoading() {
                return this.state === 'loading';
            },
            isStopped() {
                return !(this.isPlaying || this.isLoading);
            }
        },
        methods: {
            click: function () {
                this.$emit((this.isPlaying) ? 'stop' : 'play');
            },
        }
    }
</script>
<style lang="scss" scoped>
    .play-button {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            &--stopped {
                width: inherit;
                height: inherit;
                position: relative;
                svg {
                    width: inherit;
                    height: inherit;
                    position: absolute;
                }
            }
        }

        .touch-area {
            position: absolute;
            background: transparent;
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }
    }
</style>
<template>
    <div
        class="play-button"
        :style="style"
        @mouseover="isHover=true"
        @mouseleave="isHover=false"
        @click.prevent="click">
        <playing-icon
            class="icon--playing"
            :style="{ transform: `scale(${size / 24})`}"
            :color="iconColor"
            :is-playing="isPlaying"
            :is-loading="isLoading"
            v-if="(isPlaying || isLoading)"
        ></playing-icon>
        <!--
        <loading-icon
            class="icon--loading"
            :style="{ transform: `scale(${size / 24})`}"
            :color="iconColor"
            v-if="isLoading"
        ></loading-icon>
        -->
        <div
            class="icon--stopped"
            :style="{ width: `${size}px`, height: `${size}px`}"
            v-if="isStopped">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M8 5v14l11-7z"/>
            </svg>
        </div>
        <div
            class="touch-area"></div>

    </div>
</template>
