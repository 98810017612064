const DEBUG = false;

import Vue from 'vue';
import { wrapRequest } from './requests';
import { WebSocketBridge } from 'django-channels';
import APIClient from "../api/client";
import store from './index';

const NOW_PLAYING_ENDPOINT = '/api/v1/bcmon/now-playing/';


let _store_ref = {};

const state = {
  now_playing: {},
};
const getters = {
  now_playing: state => state.now_playing,
  now_playing_by_program_uuid: (state) => (uuid) => {
    return state.now_playing.find(p => p.program_uuid === uuid);
    // return state.now_playing[uuid] || null;
  },
};
const mutations = {
    update_now_playing : (state, payload) => {
      if (DEBUG) console.debug('mutations - update_airplay', payload);
      const index = state.now_playing.map(({program_uuid}) => program_uuid).indexOf(payload.program_uuid);

      if(index > -1) {
          Vue.set(state.now_playing, index, payload);
      }

      // Vue.set(state.arr, index, payload);
      // state.messages.unshift(payload);
    },
    set_now_playing : (state, payload) => {
      if (DEBUG) console.debug('mutations - update_airplay', payload);
      state.now_playing = payload;
    },
};

const actions = {};

/**********************************************************************
 * websocket handling / store updates
 *********************************************************************/
const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
let ws_host = window.location.host;

// just for webpack devserver scenario - connect directly to backend
if(parseInt(window.location.port) === 3000){
    ws_host = 'local.digris.ch:8080';
    console.info('webpack assumed, setting ws host to:', ws_host)
}

const ws_url = ws_scheme + '://' + ws_host + "/ws/bcmon/";
const wsb = new WebSocketBridge();
wsb.connect(ws_url);
wsb.listen((data, stream) => {
    // console.log('bcmon:', data.text, data);
    store.commit('bcmon/update_now_playing', data);
});

/**********************************************************************
 * load initial data
 *********************************************************************/
const load_now_playing = async (store) => {

      const url = NOW_PLAYING_ENDPOINT;
      if (DEBUG) console.debug('actions - load_now_playing', url);
      let _r = wrapRequest(APIClient.get);
      let {data} = await _r(url);

      store.commit('bcmon/set_now_playing', data.results);
};

//load initial data
// setTimeout(() => {
//   load_now_playing(store);
// }, 10);

setImmediate(() => {
  load_now_playing(store);
});


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
